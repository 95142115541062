import React, { useState, KeyboardEvent, useEffect } from 'react';
import { Box, TextField, FormControl, Link } from '@mui/material';
import {MainPageConstants} from "../../../Common/MainPageConstants";
import {CommonUtils} from "../../../../../Common/CommonUtils";
import {ProductService} from "../../../../../Services/ProductService";
import {LogUtils} from "../../../../../Common/LogUtils";
import {EcmgApiError} from "../../../../../Common/EcmgApiError";
import {useSnackbar} from "../../../../Common/Provider/SnackbarContext";

interface FreeMemoFieldProps {
  stockCode: string;
  freeMemoType: number;
  freeNote: string;
  isDisabled?: boolean;
  height?: string | number;
  width?: string | number;
  selectRowOnClick?: boolean;
}

/**
 * フリーメモフィールド
 * @constructor
 * @param props
 */
export const FreeMemoField = (props: FreeMemoFieldProps) => {
  /**
   * 定数
   */
  const BOX_STYLE = {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    padding: '10px',
    color: MainPageConstants.COLOR_DARK_GRAY,
    minHeight: props.height || 'auto',
    width: props.width || 'auto',
  };


  /**
   * useSnackbar
   */
  const { showSnackbarMessage } = useSnackbar();

  /**
   * useState
   */
  const [state, setState] = useState({
    isEditing: false,
    localValue: props.freeNote || '',
    processing: false,
    selection: { start: 0, end: 0 },
  });

  /**
   * 状態更新
   * @param newState
   */
  const updateState = (newState: Partial<typeof state>) => {
    setState(prevState => ({ ...prevState, ...newState }));
  };

  /**
   * メモ更新時処理
   */
  useEffect(() => {
    if (state.isEditing) {
      return;
    }

    updateState({ localValue: props.freeNote || '' });
  }, [props.freeNote]);

  /**
   * ダブルクリック時処理
   */
  const handleDoubleClick = () => {
    updateState({ isEditing: true });
  };

  /**
   * キープレス時
   * @param event
   */
  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && event.shiftKey) {
      updateState({ isEditing: false });
      updateFreeMemo(state.localValue).then();
    }
  };

  /**
   * フリーメモ更新
   */
  const updateFreeMemo = async (valueText: string) => {
    let message = '';

    try {
      updateState({ processing: true });

      // フリーメモ更新処理
      message = await ProductService.getInstance().updateFreeMemo(props.stockCode, props.freeMemoType, valueText);

      updateState({processing: false});

    } catch (e) {
      LogUtils.ex(e);

      updateState({ processing: false});

      if (e instanceof EcmgApiError && e.message != null) {
        message = 'エラー：' + e.message;
      }
      if (CommonUtils.isEmpty(message)) {
        message = 'フリーメモ更新処理でエラーが発生しました。';
      }
    }

    showSnackbarMessage(message);
  };


  /**
   * フォーカスアウト時
   */
  const handleBlur = () => {
    // 2024/11/15 フォーカスアウト時に編集モードをキャンセルしないようにする
    //updateState({ isEditing: false, localValue: props.freeNote || '' });
  };

  /**
   * クリック時処理
   */
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (state.isEditing || !props.selectRowOnClick) {
      return;
    }

    const selection = window.getSelection();
    if (!selection) {
      return;
    }
    selection.removeAllRanges();

    // クリックされた点に基づいて範囲を取得（document.caretRangeFromPointを使用）
    const range = document.caretRangeFromPoint(event.clientX, event.clientY);
    if (!range || !range.startContainer || range.startContainer.nodeType !== Node.TEXT_NODE) {
      return;
    }

    // テキストノードとその中のオフセットを取得
    const textNode = range.startContainer as Text;
    const clickOffset = range.startOffset;

    // テキスト全体を取得
    const text = textNode.textContent;
    if (!text) {
      return;
    }

    // クリックされた位置から行の開始と終了を見つける
    const start = text.lastIndexOf('\n', clickOffset - 1) + 1;
    const end = text.indexOf('\n', clickOffset);
    const endOffset = end === -1 ? text.length : end;

    // 選択範囲を設定
    const newRange = document.createRange();
    newRange.setStart(textNode, start);
    newRange.setEnd(textNode, endOffset);
    selection.addRange(newRange);
  };



  /**
   * メモをリンク付きでレンダリング
   * @param text
   */
  const renderMemoWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).flatMap((part, i) =>
      i % 2 === 0 ? (
        part.split('\n').map((line, lineIndex) =>
          // Check if it's the last line; if not, add a <br /> after it
          <React.Fragment key={`${i}-${lineIndex}`}>
            {line}
            {lineIndex < part.split('\n').length - 1 ? <br /> : null}
          </React.Fragment>
        )
      ) : (
        <Link
          href={part}
          key={i}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            whiteSpace: 'break-spaces',
            overflowWrap: 'break-word',
            wordBreak: 'break-all'
          }}>
          {part}
        </Link>
      )
    );
  };

  /**
   * 描画
   */
  return (
    <Box onDoubleClick={handleDoubleClick} sx={{cursor: "pointer"}} onClick={onClick}>
      {!state.isEditing ? (
        <Box sx={BOX_STYLE} >
          {renderMemoWithLinks(state.localValue)}
        </Box>
      ) : (
        <FormControl fullWidth>
          <TextField
            value={state.localValue}
            onChange={(e) => updateState({ localValue: e.target.value })}
            onKeyDown={handleKeyPress}
            onBlur={handleBlur}
            autoFocus
            multiline
            disabled={props.isDisabled}
            InputProps={{
              sx: {
                alignItems: 'flex-start',  // テキストを上に寄せる
                minHeight: props.height || 'auto',
                width: props.width || 'auto',
              },
            }}
          />
        </FormControl>
      )}
    </Box>
  );
};

